import { FormatIcu } from '@tolgee/format-icu';
import { DevTools, Tolgee, DevBackend } from '@tolgee/web';
import { InContextTools } from '@tolgee/web/tools';

const apiKey = process.env.NEXT_PUBLIC_TOLGEE_API_KEY;
const viewKey = 'tgpak_gjpwcyjvmf2tg5twofthky3ig5twy3dwnzvw433rmm4dq';
const apiUrl = process.env.NEXT_PUBLIC_TOLGEE_API_URL;

export const ALL_LANGUAGES = ['en', 'cs', 'de', 'fr'];

export const DEFAULT_LANGUAGE = 'en';

export function TolgeeBase() {
    if (!apiUrl) {
        throw new Error('NEXT_PUBLIC_TOLGEE_API_URL is not defined');
    }

    const instance = Tolgee()
        .use(FormatIcu())
        .use(
            InContextTools({
                credentials: {
                    apiKey: viewKey,
                    apiUrl,
                    projectId: 2
                }
            })
        );

    if (process.env.NODE_ENV === 'development') {
        instance.use(DevTools());
    }

    return instance.updateDefaults({
        fallbackLanguage: DEFAULT_LANGUAGE,
        apiKey: viewKey,
        availableNs: ['landingPage'],
        apiUrl,
        staticData: {
            en: () => import('../../messages/en.json'),
            cs: () => import('../../messages/cs.json')
        }
    });
}
