'use client';

import { TolgeeBase } from './shared';
import { CachePublicRecord, TolgeeProvider, TolgeeStaticData } from '@tolgee/react';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

type Props = {
    language: string;
    staticData: TolgeeStaticData | CachePublicRecord[];
    children: React.ReactNode;
};

export const TolgeeNextProvider = ({ language, staticData, children }: Props) => {
    const router = useRouter();
    const tolgeeInstance = TolgeeBase().init({
        language,
        staticData: staticData ?? undefined
    });

    useEffect(() => {
        const { unsubscribe } = tolgeeInstance.on('permanentChange', () => {
            router.refresh();
        });
        return () => unsubscribe();
    }, [tolgeeInstance, router]);

    return (
        <TolgeeProvider
            tolgee={tolgeeInstance}
            fallback="Loading"
            options={{ useSuspense: false }}
        >
            {children}
        </TolgeeProvider>
    );
};
